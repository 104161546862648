import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./DuvidasFAQ.css";
import perguntas from "../../assets/img/perguntas.png";
import AcordionFAQ from "../AccordionFAQ/AccordionFAQ";

const DuvidasFAQ = () => {
    return (
      <Container id="politica">
        <Row>
          <Col id="gray">
            <h1 id="tituloPolitica">
              <img src={perguntas} alt="perguntas" style={{width:'50%'}}></img>
              <p style={{fontSize:'20px', marginTop:'50px'}}>Bem-vindo(a) à Clínica NexaSaúde. <br /> 
              Aqui compilamos algumas das perguntas mais frequentes feitas por nossos pacientes. <br />
              Caso ainda tenha alguma dúvida, entre em contato pelos nossos canais.</p>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <AcordionFAQ />
          </Col>
        </Row>
      </Container>
)}

export default DuvidasFAQ;