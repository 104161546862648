import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import "react-alice-carousel/lib/alice-carousel.css";
import "../styles/politica.css";
import logo from "../assets/img/Logo.png";
import logoBlue from "../assets/img/logoBlue.png";
import ProgramaFAQ from "../components/ProgramaFAQ/PoliticaPrivacidade";

export default function Politica() {
  return (
    <div>
      <div className="custom-navbar-container">
        <Navbar expand="xl" className="custom-navbar">
          <Container>
            <Navbar.Brand href="/home">
              <img id="logo" src={logo} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              style={{ backgroundColor: "#fff" }}
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav
                className="me-auto"
                style={{ borderRadius: "20px", paddingLeft: "20px" }}
              >
                <Nav.Link href="/Home">Home</Nav.Link>
                &emsp;
                <Nav.Link href="/Home">A NexaSaúde</Nav.Link>
                &emsp;
                <Nav.Link href="/Home">Médicos e Especialidades</Nav.Link>
                &emsp;
                <Nav.Link eventKey={2} href="/Home">
                  Contato
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <ProgramaFAQ />

      <footer id="footer" class="footer-1">
        <div className="main-footer widgets-dark typo-light">
          <div className="container">
            <div className="row" style={{ marginLeft: "100px" }}>
              <div className="col-xs-12 col-sm-6 col-md-3">
                <div className="widget subscribe no-box">
                  <h5 className="widget-title">
                    <img
                      id="logoFooter"
                      src={logoBlue}
                      alt="Logo"
                      style={{ marginTop: "50px" }}
                    />
                  </h5>
                </div>
              </div>

              <div className="col-xs-12 col-sm-6 col-md-6">
                <div className="widget no-box">
                  <ul
                    className="thumbnail-widget"
                    style={{ marginTop: "-10px" }}
                  >
                    <li>
                      <div
                        className="thumb-content"
                        style={{ marginTop: "50px" }}
                      >
                        <a href="/Home" id="thumbColor">
                          Home
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb-content">
                        <a href="/Home" id="thumbColor">
                          A Clínica
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb-content">
                        <a href="/Home" id="thumbColor">
                          Médicos e Especialidades
                        </a>
                      </div>
                    </li>
                    <li>
                      {/*<div className="thumb-content">
                        <a href="." id="thumbColor">
                          Blog
                        </a>
                    </div> */}
                    </li>
                    <li>
                      <div className="thumb-content">
                        <a href="/Home" id="thumbColor">
                          Contato
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xs-12 col-sm-6 col-md-3">
                <div className="widget no-box">
                  <h5
                    className="widget-title"
                    style={{ marginTop: "110px", textAlign: "right" }}
                  >
                    <div className="thumb-content" id="faq">
                      <a href="/Politica" id="thumbColor2">
                        Politica de privacidade
                      </a>
                    </div>
                    <div className="thumb-content" id="faq2">
                      <a href="/Duvidas" id="thumbColor2">
                        FAQ
                      </a>
                    </div>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <p>
                  Copyright Clínica Nexa © 2023. Todos os direitos reservados.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
