import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import "react-alice-carousel/lib/alice-carousel.css";
import Card from "react-bootstrap/Card";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../Medics/Medic.css";
import CardMedic from "../Cards/Cards";

function Medic() {
  return (
    <div
      className="d-flex justify-content-center"
      style={{ marginTop: "80px" }}
    >
      <Card body className="cardT-dark" id="especialidadeCard">
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="profile" title="Endocrinologia e Metabologia">
           {/*<Container>
                <Row>
                  <Col>
                    <CardMedic />
                  </Col>
                  <Col>
                    <CardMedic />
                  </Col>
                  <Col>
                    <CardMedic />
                  </Col>
                </Row>
              </Container> */}
          </Tab>
          <Tab eventKey="home" title="Geriatria">
           {/* <Container>
              <Row>
                <Col>
                  <CardMedic />
                </Col>
                <Col>
                  <CardMedic />
                </Col>
                <Col>
                  <CardMedic />
                </Col>
              </Row>
            </Container> */}
          </Tab>
          <Tab eventKey="contact" title="Neurologia Clínica">
            {/*<Container>
              <Row>
                <Col>
                  <CardMedic />
                </Col>
                <Col>
                  <CardMedic />
                </Col>
                <Col>
                  <CardMedic />
                </Col>
              </Row>
          </Container> */}
          </Tab>
          <Tab eventKey="Especialidade4" title="Neuropediatria">
            {/* <Container>
                  <Row>
                    <Col>
                      <CardMedic />
                    </Col>
                    <Col>
                      <CardMedic />
                    </Col>
                    <Col>
                      <CardMedic />
                    </Col>
                  </Row>
                </Container> */}
          </Tab>
          <Tab eventKey="Especialidade5" title="Nutricionista">
            {/* <Container>
                <Row>
                  <Col>
                    <CardMedic />
                  </Col>
                  <Col>
                    <CardMedic />
                  </Col>
                  <Col>
                    <CardMedic />
                  </Col>
                </Row>
              </Container> */}
          </Tab>
          <Tab eventKey="Especialidade6" title="Nutrologia">
            {/* <Container>
              <Row>
                <Col>
                  <CardMedic />
                </Col>
                <Col>
                  <CardMedic />
                </Col>
                <Col>
                  <CardMedic />
                </Col>
              </Row>
            </Container> */}
          </Tab>
          <Tab eventKey="Especialidade7" title="Psicologia">
            {/* <Container>
              <Row>
                <Col>
                  <CardMedic />
                </Col>
                <Col>
                  <CardMedic />
                </Col>
                <Col>
                  <CardMedic />
                </Col>
              </Row>
          </Container> */}
          </Tab>
          <Tab eventKey="Especialidade8" title="Psiquiatria">
            {/* <Container>
              <Row>
                <Col>
                  <CardMedic />
                </Col>
                <Col>
                  <CardMedic />
                </Col>
                <Col>
                  <CardMedic />
                </Col>
              </Row>
          </Container> */}
          </Tab>
        </Tabs>
      </Card>
    </div>
  );
}

export default Medic;
