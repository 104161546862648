import React, { useState } from "react";
import "./Contact.css";
import Form from "react-bootstrap/Form";
import { BiPhoneCall, /*BiEnvelope*/ BiLogoWhatsapp } from "react-icons/bi";
import { FiMapPin } from "react-icons/fi";
import Maps from "../GoogleMaps/Maps.jsx";
import HomeFAQ from "../HomeFAQ/HomeFAQ";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputMask from 'react-input-mask';

const Contact = () => {
  const [show, setShow] = useState(false);
  const [telefone, insereTelefone] = useState('');
  const [nome, insereNome] = useState('');
  const [emailDestinatario, insereEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mensagem, insereMensagem] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!nome || !telefone || !emailDestinatario) {
      alert('Todos os campos são obrigatórios.');
      return;
    }

    if (!validateEmail(emailDestinatario)) {
      setEmailError('Por favor, insira um e-mail válido.');
      return;
    }

    const data = {
      nome: nome,
      telefone: telefone,
      emailDestinatario: emailDestinatario,
      mensagem: mensagem,
    };

    try {
      const response = await fetch('https://www.unimeduberlandia.coop.br/ApisNovoPortal/emails/api_envia_email_nexa.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result);

        insereNome('');
        insereTelefone('');
        insereEmail('');
        insereMensagem('');

        handleShow();
      } else {
        console.error('Erro:', response.statusText);
      }
    } catch (error) {
      console.error('Erro:', error);
    }
  };

  return (
    <div style={{ backgroundColor: "#302982", alignItems: "center", minHeight: "800px" }}>
      <div className="container mt-8">
        <div className="row">
          <div className="col-md-8" style={{ marginTop: "-0px", paddingLeft: "170px" }}>
            <h1 id="welcomeContact">Entre em contato</h1>
            <p id="apresentacaoContact">
              <ul style={{ marginLeft: "-32px" }}>
                <li>
                  <BiPhoneCall style={{ color: "#F46207" }} /> (34) 3239-6959
                </li>
                <li>
                  <BiLogoWhatsapp style={{ color: "#F46207" }} /> (34) 9 9631-0059
                </li>
                {/* <li>
                  <BiEnvelope style={{ color: "#F46207" }} /> atendimento@clinicanexasaude.com.br
                </li> */}
                <li>
                  <FiMapPin style={{ color: "#F46207" }} /> R.Martinésia, 113 - Centro, Uberlândia-MG, 38400-109
                </li>
              </ul>
              <Maps />
              <b style={{ color: "#fff" }}>Funcionamento:</b>
              <p style={{ color: "#fff" }}>
                De segunda a sexta-feira das 7h às 19h
                <br />
                Sábados, Domingos e feriados, clínica fechada.
              </p>
            </p>
          </div>
          <div className="col-md-4">
            <Form id="FormTwo" onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="controlId">
                <Form.Control
                  id="border"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => insereNome(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="controlId">
                <InputMask
                  mask="(99) 99999-9999"
                  value={telefone}
                  onChange={(e) => insereTelefone(e.target.value)}
                  required
                >
                  {() => <Form.Control id="border" type="text" placeholder="Telefone" />}
                </InputMask>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  id="border"
                  type="email"
                  placeholder="E-mail"
                  value={emailDestinatario}
                  onChange={(e) => {
                    insereEmail(e.target.value);
                    setEmailError('');
                  }}
                  required
                />
                {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicMensagem">
                <Form.Control
                  id="border"
                  as="textarea"
                  placeholder="Descreva sua solicitação"
                  value={mensagem}
                  onChange={(e) => insereMensagem(e.target.value)}
                  required
                />
              </Form.Group>
              <button type="submit" className="btn btn-primary" id="moreContact">
                Enviar
              </button>
            </Form>
          </div>
          <h1 id="frequentes">Confira as perguntas frequentes</h1>
          <HomeFAQ />
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ marginLeft: '85px' }}>Agradecemos o contato!</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>Obrigado por entrar em contato conosco, logo retornaremos sua solicitação!</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Finalizar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Contact;