import React from "react";
import { Container } from "react-bootstrap";
import "./PoliticaPrivacidade.css";
import PoliticaTermos from "../PoliticaTermos/PoliticaTermos";

const PoliticaPrivacidade = () => {
  return (
    <Container id="politica">
      <PoliticaTermos/>
    </Container>
  );
};

export default PoliticaPrivacidade;
