import Accordion from 'react-bootstrap/Accordion';
import "../HomeFAQ/HomeFAQ.css";
import { BiPhoneCall, /*BiEnvelope */ BiLogoWhatsapp } from "react-icons/bi";

function HomeFAQ() {
  return (
    <Accordion className="accordionHome" style={{width:'67%'}}>
      <Accordion.Item id="acord" class="rounded" eventKey="0">
        <Accordion.Header id="acordtitle">O que é uma Clínica Médica Multi especialidades?</Accordion.Header>
        <Accordion.Body>
        É um centro de saúde que oferece uma variedade de especialidades médicas em um único espaço físico. Isso significa que você pode consultar com diferentes médicos especialistas no mesmo local, tornando o acesso e cuidado mais práticos.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="1">
        <Accordion.Header id="acordtitle">Quais especialidades médicas estão disponíveis?</Accordion.Header>
        <Accordion.Body>
        <ol>
          <li>• Endocrinologia</li>
          <li>• Fonoaudiologia</li>
          <li>• Geriatra</li>
          <li>• Neurologia</li>
          <li>• Nutrição</li>
          <li>• Nutrologia</li>
          <li>• Psicologia</li>
          <li>• Psiquiatria</li>
          <li>• Terapia ocupacional</li>
        </ol>
        E em breve teremos outras 22 especialidades disponíveis.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="2">
        <Accordion.Header id="acordtitle">Como faço para agendar uma consulta?</Accordion.Header>
        <Accordion.Body>
          Ligando diretamente para a nossa clínica no telefone:  <BiPhoneCall style={{ color: "#FFFFFF" }} /> (34) 3239-6959, ou agendando online pelo WhatsApp:  <BiLogoWhatsapp style={{ color: "#FFFFFF" }} /> (34) 9 9631-0059.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="3">
        <Accordion.Header id="acordtitle">Quais são os horários de funcionamento da clínica?</Accordion.Header>
        <Accordion.Body>
          De segunda a sexta-feira das 7h às 19h. <br />
          Sábados, Domingos e feriados, clínica fechada.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="4">
        <Accordion.Header id="acordtitle">Quais convênios de saúde vocês aceitam?</Accordion.Header>
        <Accordion.Body>
          No momento aceitamos somente convênio Unimed.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="5">
        <Accordion.Header id="acordtitle">Quanto tempo dura uma consulta médica?</Accordion.Header>
        <Accordion.Body>
          A duração de uma consulta médica pode variar de acordo com a especialidade e a natureza da consulta. As de rotina aproximadamente de 15 a 30 minutos, enquanto consultas mais complexas podem exigir mais tempo. Certifique-se de perguntar ao seu médico sobre a duração esperada da sua consulta.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="6">
        <Accordion.Header id="acordtitle">O que devo trazer para minha consulta médica?</Accordion.Header>
        <Accordion.Body>
          É importante trazer:

          <ol>
            <li>• Documento de identificação</li>
            <li>• Carteirinha do convênio</li>
            <li>• Perguntas ou preocupações que você gostaria de discutir com o médico.</li>
        </ol>

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="7">
        <Accordion.Header id="acordtitle">Qual é a política de cancelamento de consultas?</Accordion.Header>
        <Accordion.Body>
          O paciente deve informar com antecedência pelo menos 24h de antecedência caso precise cancelar ou reagendar uma consulta. Nossa política de cancelamento pode variar, portanto, entre em contato conosco para obter informações específicas sobre o nosso procedimento de cancelamento.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="8">
        <Accordion.Header id="acordtitle">Qual é a nossa prioridade número um?</Accordion.Header>
        <Accordion.Body>
          Nossa principal prioridade é a sua saúde e bem-estar. Estamos aqui para oferecer cuidados médicos de qualidade, orientação e apoio a todos os nossos pacientes. Se você tiver alguma dúvida ou preocupação, não hesite em nos contatar.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="9">
        <Accordion.Header id="acordtitle">Como funciona o autoatendimento da clínica?</Accordion.Header>
        <Accordion.Body>
          [Explicar jornada]
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default HomeFAQ;