import React from 'react';
import medicos from '../../assets/img/medicos.png';
import './SideImageThree.css';

const SideImageTwo = () => {
  return (
    <div style={{backgroundColor:"#f46207", alignItems:"center", height:"750px"}}>
    <div className="container mt-8">
      <div className="row">
        <div className="col-md-12" style={{marginTop:"-50px"}}>
          <h1 id="welcome02">Sua consulta cancelada é a consulta liberada de outro paciente</h1>
          <br />
          <h1 id="infowelcome">Agendou uma consulta e não vai poder comparecer?</h1>
          <h1 id="infowelcomeBig">Ligue e desmarque.</h1>
          <h1 id="infowelcome">Outros pacientes podem estar esperando um encaixe.</h1>
          <h1 id="infowelcomeBig">Entre em contato pelo telefone</h1>
          <h1 id="infowelcomeTel">(34) 3239-6959</h1>
          <h1 id="infowelcomeWhats">ou WhatsApp</h1>
          <h1 id="infowelcomeTel">(34) 9 9631-0059</h1>
          <h1 id="infowelcome">E fale com a gente para cancelar sua consulta e remarcar em um novo horário que caiba na sua agenda!</h1>
        </div>
      </div>
    </div>
    </div>
  );
};

export default SideImageTwo;
