import Table from 'react-bootstrap/Table';
import './Table.css'

function TablePolitica() {
  return (
    <Table bordered hover size="sm" style={{borderColor:"gray"}}>
      <thead>
        <tr>
          <th style={{textAlign:"center", backgroundColor:"#302982", color:"white"}}>Data</th>
          <th style={{textAlign:"center", backgroundColor:"#302982", color:"white"}}>Versão</th>
          <th style={{textAlign:"center", backgroundColor:"#302982", color:"white"}}>Descrição</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{textAlign:"center"}}>04/11/2020</td>
          <td style={{textAlign:"center"}}>1.0</td>
          <td style={{textAlign:"center"}}>Aprovação</td>
        </tr>
        <tr>
          <td style={{textAlign:"center"}}>04/10/2023</td>
          <td style={{textAlign:"center"}}>2.0</td>
          <td style={{textAlign:"center"}}>Unificação das Políticas de Privacidade e de Proteção de Dados,<br /> com revisão ampla e irrestrita.</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default TablePolitica;