import React from 'react';
import './Maps.css';

function Maps() {
  return (
    <div id="map-container">
      <iframe style={{ borderRadius: '15px' }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3744.582169184328!2d-48.27354459999999!3d-18.913457000000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94a4453441f54c8b%3A0x51be9f0331d8e9c0!2sCl%C3%ADnica%20NexaSa%C3%BAde!5e0!3m2!1sen!2suk!4v1645302548726!5m2!1sen!2suk"
        width="400px"
        height="300px"
        frameBorder="0"
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </div>
  );
}

export default Maps;
