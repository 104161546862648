import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./PoliticaPrivacidade.css";
import TablePolitica from "../Table/Table";
import cadeado from "../../assets/img/privacidade.png";
import Acordion from "../Accordion/Accordion";

const ProgramaFAQ = () => {
    return (
      <Container id="politica">
        <Row>
          <Col id="gray">
            <h1 id="tituloPolitica">
              <img src={cadeado} alt="privacidade"></img>
              <p style={{fontSize:'20px', marginTop:'50px'}}>Nossa Missão é cuidar da saúde e também da proteção de dados.<br />
                 Temos por objetivo garantir a conformidade de nossas operações assegurando o seu direito à Privacidade.<br />
                 Conheça mais sobre o nosso programa Privacidade e Proteção de Dados.</p>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Acordion />
          </Col>
        </Row>
      </Container>
)}

export default ProgramaFAQ;