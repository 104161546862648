import React from 'react';
import medicos from '../../assets/img/medicos.png';
import './SideImageTwo.css';

const SideImageTwo = () => {
  return (
    <div style={{backgroundColor:"#fff", alignItems:"center", height:"480px"}}>
    <div className="container mt-8">
      <div className="row">
        <div className="col-md-8" style={{marginTop:"-50px", paddingLeft:"170px"}}>
          <h1 id="welcome01">Atendimento Unimed</h1>
          <p id="apresentacao01">
            A Clínica NexaSaúde tem parceria com a <br /> Unimed Uberlândia. Oferecemos aos <br /> beneficiários Unimed atendimento de alta <br /> qualidade em todas as especialidades médicas <br /> que nossa clínica disponibiliza. Essa colaboração <br /> reforça nosso compromisso em proporcionar <br /> cuidados médicos de excelência aos nossos <br /> pacientes, garantindo acesso a uma ampla <br />gama de especialidades médicas.
          </p>
          <button className="btn btn-primary" id="more" style={{display:"none"}}>Saiba Mais</button>
        </div>
        <div className="col-md-4">
          <img
            src={medicos}
            className="img-fluid rounded" id="imgTwo"
            alt="Imagem"
          />
        </div>
      </div>
    </div>
    </div>
  );
};

export default SideImageTwo;
