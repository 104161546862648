import React from 'react';
import espera from '../../assets/img/Consultorio.png';
import './SideImage.css';

const SideImage = () => {
  return (
    <div style={{backgroundColor:"#fff", alignItems:"center", height:"470px"}}>
    <div className="container mt-8">
      <div className="row">
        <div className="col-md-4">
          <img
            src={espera}
            className="img-fluid rounded" id="imggg"
            alt="Imagem"
          />
        </div>

        <div className="col-md-8" style={{marginTop:"-40px", paddingLeft:"150px"}}>
          <h1 id="welcome">Bem-vindos à <br /> Clínica NexaSaúde</h1>
          <p id="apresentacao">
            A saúde encontra uma nova dimensão <br /> de cuidado e dedicação. Surgimos com <br /> objetivo de atender a nossa cidade e <br /> região com um excelente corpo clínico e <br />as especialidades mais procuradas.
          </p>
          <button className="btn btn-primary" id="more" style={{display:"none"}}>Saiba Mais</button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default SideImage;
