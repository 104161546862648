import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import  Home  from "./pages/home.jsx";
import Politica from './pages/politica.jsx';
import FAQ from './pages/FAQ.jsx';
import Duvidas from './pages/Duvidas.jsx';
//import Blog from './components/Blog/Blog.jsx';


 
function App() {

  
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Home />}> </Route>
        <Route path="/Politica" element={<Politica />}> </Route>
        <Route path="/FAQ" element={<FAQ />}> </Route>
        <Route path="/Duvidas" element={<Duvidas />}></Route>
        {/* <Route path="/Blog" element={<Blog />}></Route> */}
      </Routes>
    </Router>
  );
}
 
export default App;