
import '../PoliticaTermos/PoliticaTermos.css'


const PoliticaTermos = () => {
  return (
    <div>
      <p id="PoliticaText">Política de Privacidade e Proteção de Dados</p>
      <iframe
        title="Página Externa"
        src='https://www.clinicanexasaude.com.br/politica.html'
        width="100%"
        height="6800"
      ></iframe>
    </div>
  );
};

export default PoliticaTermos;