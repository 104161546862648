import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "../styles/home.css";
import banner1 from "../assets/img/nexasaude.png";
import banner2 from "../assets/img/bannernew1.png";
import banner3 from "../assets/img/bannernew2.png";
import banner4 from "../assets/img/bannernew3.png";
import logo from "../assets/img/Logo.png";
import logoBlue from "../assets/img/logoBlue.png";
import Medic from "../components/Medics/Medic";
import SideImage from "../components/SideImage/SideImage";
import SideImageTwo from "../components/SideImageTwo/SideImageTwo";
import SideImageThree from "../components/SideImageThree/SideImageThree";
import Contact from "../components/Contact/Contact";


export default function Home() {
  
  return (
    <div>
      <div className="custom-navbar-container">
        <Navbar expand="xl" className="custom-navbar">
          <Container>
            <Navbar.Brand className="nomeLogo" href="#home">
              <img id="logo" src={logo} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              style={{ backgroundColor: "#fff" }}
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav
                className="me-auto"
        
              >
                <Nav.Link href="#topo">Home</Nav.Link>
                &emsp;
                <Nav.Link href="#welcome">A NexaSaúde</Nav.Link>
                &emsp;
                <Nav.Link href="#espeTitle">Médicos e Especialidades</Nav.Link>
                &emsp;
                <Nav.Link eventKey={2} href="#footer">
                  Contato
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <div className="bg-purple" id="topo">
        <Carousel className="custom-carousel">
          <Carousel.Item>
            <img id="carouselimg"
              className="d-block w-100"
              src={banner1}
              alt="Primeira imagem"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img id="carouselimg" className="d-block w-100" src={banner2} alt="Segunda imagem" />
          </Carousel.Item>
          <Carousel.Item>
            <div className="img">
            <img id="carouselimg" 
              className="d-block w-100"
              src={banner3}
              alt="Terceira imagem"
            />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="img">
            <img id="carouselimg" 
              className="d-block w-100"
              src={banner4}
              alt="Quarta imagem"
            />
            </div>
          </Carousel.Item>
        </Carousel>
        <SideImage />


        <h1 id="espeTitle">Especialidades</h1>
        <h3 id="espeTitle2">A sua saúde é nossa prioridade</h3>
        <Medic />
        <SideImageTwo />
        <div id="footer">
        <SideImageThree />
        <Contact />
        </div>
        <footer class="footer-1">
          <div className="main-footer widgets-dark typo-light">
            <div className="container">
              <div className="row" style={{ marginLeft: "100px" }}>
                <div className="col-xs-12 col-sm-6 col-md-3">
                  <div className="widget subscribe no-box">
                    <h5 className="widget-title">
                      <img
                        id="logoFooter"
                        src={logoBlue}
                        alt="Logo"
                        style={{ marginTop: "-0px" }}
                      />
                    </h5>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-6">
                  <div className="widget no-box">
                    <ul
                      className="thumbnail-widget"
                      style={{ marginTop: "-10px"}}
                    >
                      <li>
                        <div className="thumb-content">
                          <a href="#topo" id="thumbColor">
                            Home
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="thumb-content">
                          <a href="#welcome" id="thumbColor">
                            A Clínica
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="thumb-content">
                          <a href="#espeTitle" id="thumbColor">
                            Médicos e Especialidades
                          </a>
                        </div>
                      </li>
                      {/*<div className="thumb-content">
                        <a href="." id="thumbColor">
                          Blog
                        </a>
                    </div> */}
                      <li>
                        <div className="thumb-content">
                          <a href="#footer" id="thumbColor">
                            Contato
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-3" id="foot">
                  <div className="widget no-box">
                    <h5
                      className="widget-title" id="nameFooter" 
                      style={{ marginTop: "110px", textAlign: "right" }}
                    >
                      <div className="thumb-content" id="faq">
                        <a href="/faq" id="thumbColor2">
                          <p id="political">Política de privacidade</p>
                        </a>
                      </div>
                      <div className="thumb-content" id="faq2">
                        <a href="/Duvidas" id="thumbColor2">
                          FAQ
                        </a>
                      </div>
                      <div className="thumb-content" id="dra" style={{marginTop:'20px'}}>
                        <p href="/faq" id="thumbColor2">
                        Diretora Técnica/Médica<br />
                        <p id="crm">
                        Liliana Silva<br />
                        CRM 47434<br />
                        RQE 23802
                        </p>
                        </p>
                      </div>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-copyright">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <p>
                    Copyright SAUDIA ASSISTENCIA MEDICA LTDA.(CNPJ: 49.481.538/0002-35) © 2023. Todos os direitos reservados. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}