import Accordion from 'react-bootstrap/Accordion';
import "../Accordion/Accordion.css";
import { AccordionButton } from 'react-bootstrap';

function Acordion() {
  return (
    <Accordion>
      <Accordion.Item id="acordone" class="rounded" eventKey="0">
        <AccordionButton id="buttonss">O que é a LGPD?</AccordionButton>
        <Accordion.Body id="corpo">
        A LGPD é a Lei Geral de Proteção de Dados, uma legislação brasileira que regula a coleta, armazenamento e uso de dados pessoais por empresas e organizações, visando proteger a privacidade e os direitos dos cidadãos.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="1">
      <AccordionButton id="buttonss">A quem se aplica?</AccordionButton>
        <Accordion.Body id="corpo">
        A LGPD se aplica a todas as empresas, organizações e pessoas que realizam o tratamento de dados pessoais no Brasil (em meio físico ou digital), independentemente do tamanho ou do setor. Também pode afetar empresas estrangeiras que coletam ou processam dados de cidadãos brasileiros.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="2">
        <AccordionButton id="buttonss">O que são dados pessoais?</AccordionButton>
        <Accordion.Body id="corpo">
        Dados pessoais são informações que podem identificar uma pessoa específica ou torná-la identificável. Isso inclui nome, endereço, número de telefone, endereço de e-mail, informações de identificação, dados de localização, entre outros.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="3">
        <AccordionButton id="buttonss">O que são dados pessoais sensíveis?</AccordionButton>
        <Accordion.Body id="corpo">
        Dados pessoais sensíveis são informações mais delicadas e privadas sobre uma pessoa, que podem ser usadas para discriminação ou causar impactos significativos em sua vida. Isso inclui informações sobre raça, etnia, orientação sexual, crenças religiosas, opiniões políticas, saúde, genética e outros aspectos similares. O tratamento desses dados é geralmente sujeito a regulamentações mais rigorosas de proteção de dados.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="4">
        <AccordionButton id="buttonss">O que são dados Anonimizados?</AccordionButton>
        <Accordion.Body id="corpo">
        Dados anonimizados são informações pessoais que foram modificadas de tal forma que não podem mais ser associadas a uma pessoa específica. Identificadores pessoais, como nomes ou números de identificação, são removidos ou alterados, tornando impossível identificar o indivíduo original a partir desses dados. Isso é feito para preservar a privacidade e a segurança das informações, permitindo ao mesmo tempo a análise estatística e o compartilhamento de dados de forma mais segura.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="5">
        <AccordionButton id="buttonss">O que é tratamento de Dados Pessoais?</AccordionButton>
        <Accordion.Body id="corpo">
        Tratamento de dados refere-se a qualquer operação realizada com dados pessoais, como coleta, armazenamento, análise, compartilhamento ou exclusão.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="6">
        <AccordionButton id="buttonss">Como a Clínica Nexa realiza a coleta de dados pessoais?</AccordionButton>
        <Accordion.Body id="corpo">
        A Clínica Nexa poderá realizar a coleta de dados pessoais através do fornecimento voluntário pelo próprio titular ou por meio de coleta automática em ambientes digitais, como o Portal Clínica Nexa (cookies) . Os procedimentos de coleta de dados estão detalhados em nossa Política de Privacidade e Proteção de Dados e você pode acessá-la clicando <a href='/Politica'>aqui</a>.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="7">
      <AccordionButton id="buttonss">Quem tem acesso aos dados pessoais coletados pela Clínica Nexa?</AccordionButton>
        <Accordion.Body id="corpo">
        Os dados pessoais e dados pessoais sensíveis são acessados apenas por pessoas autorizadas, como colaboradores da Clínica Nexa, mediante a necessidade das informações para desempenho de suas funções. Além disso, em determinadas atividades, a Clínica Nexa poderá subcontratar terceiros prestadores de serviços, conhecidos como suboperadores, para viabilização da atividade.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="8">
        <AccordionButton id="buttonss">Como a Cínica Nexa utiliza os dados pessoais de seus titulares?</AccordionButton>
        <Accordion.Body id="corpo">
        A Clínica Nexa trata os dados de seus titulares em observância aos princípios da finalidade e necessidade. Assim, os dados são utilizados para fins de prestação dos serviços contratados, cumprimento de obrigações legais e realização de ações como comunicações, programas, campanhas e pesquisas sobre temas diversos. Para saber mais acerca dos tratamentos de dados realizados pela Clínica Nexa acesse nossa Política de Privacidade e Proteção de Dados <a href='/Politica'>aqui</a>.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="9">
        <AccordionButton id="buttonss">Onde a Clínica Nexa armazena os dados pessoais?</AccordionButton>
        <Accordion.Body id="corpo">
        Os dados pessoais poderão ser armazenados em dispositivos de propriedade do Grupo Unimed Uberlândia, em arquivos físicos ou em servidores remotos terceirizados, instalados no Brasil ou no exterior, acessíveis através de serviços de cloud computing (computação em nuvem). O Grupo Unimed Uberlândia realiza o armazenamento priorizando e evidenciando a garantia da confidencialidade, disponibilidade e integridade das informações.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="10">
        <AccordionButton id="buttonss">Por quanto tempo a Clínica Nexa armazenará os dados pessoais?</AccordionButton>
        <Accordion.Body id="corpo">
        Os prazos de retenção de dados pessoais e dados pessoais sensíveis variam conforme a finalidade do tratamento. A Clínica Nexa mantém os dados pelo tempo necessário para cumprimento das finalidades de tratamento e obrigações legais aplicáveis.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="11">
        <AccordionButton id="buttonss">Como a Clínixa Nexa garante a Privacidade e Proteção de Dados?</AccordionButton>
        <Accordion.Body id="corpo">
        A Clínixa Nexa adota controles rigorosos acerca da Privacidade, Proteção de Dados e Segurança da Informação, incluindo o uso de tecnologias apropriadas, homologação de parceiros e fornecedores, controles de acesso, adequação de processos, auditorias internas e treinamentos periódicos com seus colaboradores.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="12">
        <AccordionButton id="buttonss">Quais são os direitos dos titulares e como eles podem ser exercidos?</AccordionButton>
        <Accordion.Body id="corpo">
        Os titulares de dados possuem direito de confirmação da existência de tratamento, acesso às informações coletadas, correção de dados incorretos, exclusão de dados, informações sobre o compartilhamento de seus dados, solicitação de anonimização ou bloqueio dos seus dados pessoais desnecessários ou excessivos, restrição de processamento, portabilidade dos dados e oposição ao processamento, revogação e informação sobre consequências. Os direitos dos titulares poderão ser exercícios <a href='https://unimeduberlandia-privacy.my.onetrust.com/webform/62c2e0af-7c73-4be9-9553-a3792d2ea3d4/8f7f1811-19f1-4401-8889-9bb8e0595c15'>clicando aqui</a> ou através do e-mail privacidade@unimeduberlandia.coop.br.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="13">
        <AccordionButton id="buttonss">Quem é o Encarregado(a) de Dados Pessoais da Clínica Nexa? O que ele/ela faz?</AccordionButton>
        <Accordion.Body id="corpo">
        O Encarregado(a) de Dados ou DPO é o responsável pelo Programa Privacidade e Proteção de Dados da Clínica Nexa, além de supervisionar a proteção de dados pessoais, garantindo conformidade com a LGPD. Ele assessora, monitora atividades de tratamento, atua como ponto de contato entre a Clínica Nexa e seus titulares e colabora com autoridades reguladoras. Caso você tenha dúvidas relacionadas à privacidade e proteção de dados ou queira exercer algum direito, entre em contato com nossa encarregada de proteção de dados, Adrielly Martins Neves, através do <a href="https://unimeduberlandia-privacy.my.onetrust.com/webform/62c2e0af-7c73-4be9-9553-a3792d2ea3d4/8f7f1811-19f1-4401-8889-9bb8e0595c15">Portal do Titular</a>, e-mail privacidade@unimeduberlandia.coop.br ou WhatsApp (34) 99992-1700
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item id="acord" class="rounded" eventKey="14">
        <AccordionButton id="buttonss">Onde posso obter mais informações sobre Privacidade e Proteção de Dados na Clínica Nexa?</AccordionButton>
        <Accordion.Body id="corpo">
        Para mais informações sobre como os dados pessoais são tratados e protegidos no Clínixa Nexa <a href='/Politica' style={{color:'#fff'}}>clique aqui</a> e consulte nossa Política de Privacidade e Proteção de Dados.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Acordion;